
import {
    flexRender,
    MRT_ColumnDef,
    useMaterialReactTable,
    MaterialReactTable,
} from 'material-react-table';
import {
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export type Songs = {
    songid: string;
    paraid: string;
    text: string;
};



const columns: MRT_ColumnDef<Songs>[] = [
    {
        accessorKey: 'songid',
        header: 'Song Id',
    },
    {
        accessorKey: 'paraid',
        header: 'Para Id',
    },
    {
        accessorKey: 'text',
        header: 'Stanza',
    }
];


const HomePage = () => {

    const navigate = useNavigate();

    const [data, setData] = useState<Songs[]>([])

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const getData = async () => {
        const response = await fetch('https://sacweb.mayimdazzle.com/api/songs/', {
            method: "GET",

        })

        const data = await response.json();
        setIsLoading(false);

        return data;

    }

    useEffect(() => {
        (async () => {
            try {
                const songs = await getData();
                setData(songs);
            } catch (err) {
                console.log('Error occured when fetching Songs');
            }
        })();
    }, []);



    const table = useMaterialReactTable({
        columns,
        data, //must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
        //MRT display columns can still work, optionally override cell renders with `displayColumnDefOptions`
        // enableRowSelection: true,

        positionActionsColumn: 'last',
        initialState: {
            pagination: { pageSize: 5, pageIndex: 0 },
            showGlobalFilter: true,
        },
        //customize the MRT components
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 15],
            variant: 'outlined',
        },
        paginationDisplayMode: 'pages',
        enableRowActions: true,
        renderRowActions: ({ row }) => (
            <Box>
                <IconButton onClick={() => console.info('View the song')}>
                    <VisibilityIcon />
                </IconButton>
                <IconButton onClick={() => console.info('Edit the song')}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => console.info('Delete')}>
                    <DeleteIcon />
                </IconButton>
            </Box>
        ),
        // renderRowActionMenuItems: ({ row }) => [
        //     <MenuItem>
        //     </MenuItem>,
        // ],
    });

    return <Grid container xs={12} xl={12} md={12} sm={12} className="mainDiv" style={{ display: 'flex' }} >
        <Grid container xs={12} xl={12} md={12} sm={12} style={{ padding: '10px' }} >
            <Grid item xs={6} xl={6} md={6} sm={6}>
                <span className='header1'>Song List</span>
            </Grid>
            <Grid item xs={6} xl={6} md={6} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box>
                    <Button variant="contained" onClick={() => {
                        navigate('/details', { state: { action: 'create', id: 0, songId: data[data.length - 1].songid } })
                    }}>Add New</Button>
                </Box>
            </Grid>
        </Grid>
        <Grid item xs={12} xl={12} md={12} sm={12}  >
            <Box style={{ width: '100%' }}>
                <MaterialReactTable table={table} />
            </Box>
        </Grid>
    </Grid>
}

export default HomePage