import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import { LoginFormType, LoginFormValidationType } from "../../Interface/login.interface";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const SACLogo = require('../../assets/images/SAC_Icon.png');

const LoginPage = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const PasswordIconHandeer = () => {
        setShowPassword(prevState => !prevState);
    }

    const [formState, setFormState] = useState<LoginFormType>({ username: '', password: '' })
    const [formErrorState, setFormErrorState] = useState<LoginFormValidationType>({ username: false, password: { isnull: false, isValid: false } })

    const formChangeHandler = (key: string, value: string) => {

        if (key === 'username') {
            setFormErrorState(prevState => {
                return { ...prevState, username: false }
            });

            setFormState(prevState => {
                return {
                    ...prevState,
                    username: value
                }
            })
        } else if (key === 'password') {
            setFormErrorState(prevState => {
                return {
                    ...prevState, password: {
                        isnull: false,
                        isValid: false
                    }
                }
            });

            setFormState(prevState => {
                return {
                    ...prevState,
                    password: value
                }
            })
        }

    }

    const loginHandler = async () => {
        let isValid = true;
        let tempErrorState: LoginFormValidationType = {
            username: false, password: {
                isnull: false, isValid: false
            }
        }

        if (formState.username === null || formState.username === undefined || formState.username === "") {
            tempErrorState.username = true;
            isValid = true;
        }

        if (formState.password === null || formState.password === undefined || formState.password === "") {
            tempErrorState.password.isnull = true;
            isValid = true;
        } else {
            tempErrorState.password.isValid = formState.password.length < 8
            isValid = formState.password.length < 8
        }

        console.log('haiiii', isValid)

        if (!isValid) {
            // call the api
            const response = await fetch('https://sacweb.mayimdazzle.com/api/auth/login', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Orgin': 'https://sacweb.mayimdazzle.com'
                },
                body: JSON.stringify({
                    user_name: formState.username,
                    password: formState.password
                })
            })

            const data = await response.json();

            if (response.status === 200) {

                localStorage.setItem('accessToken', data.accessToken);

                navigate('/home');

                toast.success("Login Successfully!");
            } else {
                toast.error(`${response.status} : ${data.message}`)
            }

        } else {
            setFormErrorState(tempErrorState)
        }
    }

    return <Grid container xs={12} xl={12} md={12} sm={12} className="mainDiv" >
        <Grid item xs={6} xl={6} md={6} sm={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }} >
            <img height={'60%'} width={'60%'} src={SACLogo} />
        </Grid>
        <Grid item xs={6} xl={6} md={6} sm={6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>

            <Box style={{ width: '100%' }}>
                <Grid item xs={8} xl={8} md={8} sm={8} >
                    <p className="form-lable">
                        Username <span className="mandatory"> *</span>
                    </p>
                    <TextField
                        className="input-field"
                        value={formState.username}
                        variant="outlined"
                        onChange={(e) => { formChangeHandler('username', e.target.value); }}
                        fullWidth
                    />
                    <span className="error-text">{formErrorState.username ? 'Please enter username' : ' '}&nbsp;</span>
                </Grid>
                <Grid item xs={8} xl={8} md={8} sm={8} >
                    <p className="form-lable">
                        Password<span className="mandatory"> *</span>
                    </p>
                    <TextField
                        className="input-field"
                        value={formState.password}
                        type={`${showPassword ? 'text' : 'password'}`}
                        variant="outlined" fullWidth
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    onClick={PasswordIconHandeer}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>,
                        }}
                        onChange={(e) => { formChangeHandler('password', e.target.value); }}
                    />
                    <span className="error-text">{`${formErrorState.password.isnull ? 'Please enter password' : formErrorState.password.isValid ? 'Please enter strong password' : ' '}`}&nbsp;</span>
                </Grid>
                <Grid item xs={8} xl={8} md={8} sm={8} style={{ paddingTop: '10px' }}>
                    <Button
                        onClick={loginHandler}
                        fullWidth
                        variant="contained"
                        className="btn-style"
                    >Login</Button>
                </Grid>
            </Box>

        </Grid>
    </Grid >
}

export default LoginPage