import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from '../Views/Home/home';
import DetailPage from '../Views/Details/DetailPage';
import LoginPage from "../Views/Login/Login";
import { ToastContainer } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';
const PageRoutes = () => {
    return (
        <div>
            <ToastContainer />
            <BrowserRouter>

                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    {/* <Route index element={<LoginPage />} /> */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/home" element={< HomePage />} />
                    <Route path="/details" element={<DetailPage />} />
                </Routes>

            </BrowserRouter></div>
    )

}

export default PageRoutes