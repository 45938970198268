import { Box, Button, Grid, IconButton, TextField } from "@mui/material"
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useEffect, useState } from "react";

import Textarea from '@mui/joy/Textarea';
import { useLocation } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiTextField-root": {
            margin: theme.spacing(1)
        }
    },
    textarea: {
        resize: "both"
    }
}));

const DetailPage = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [songId, setSongId] = useState<number>(0);

    const classes = useStyles();
    const location = useLocation();

    const [listData, setListData] = useState<string[]>([""]);

    const addHandler = () => {
        setListData([...listData, ""])
    }

    const removeHandler = (removeIndex: number) => {
        setListData(listData.filter((item, index) => index !== removeIndex))
    }

    const fieldChangeHandler = (currentIndex: number, value: string) => {
        console.log(value)
        setListData(listData.map((item, index) => {
            if (index === currentIndex) {
                return value
            } else {
                return item
            }
        }));
    }

    // useEffect(() => {
    //     if (location.state.action === 'action'){

    //     }else{
    //         setSongId(location.state.songId)
    //     }
    // }, [])

    const saveHandler = async () => {
        setIsLoading(true);
        console.log(location.state.songId)
        const response = await fetch('https://sacweb.mayimdazzle.com/api/songs/', {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                'Orgin': 'https://sacweb.mayimdazzle.com'
            },
            body: JSON.stringify(listData.map((item, index) => {
                return {
                    songid: location.state.songId + 1,
                    paraid: index + 1,
                    text: item
                }
            })
            )

        })

        const data = await response.json();
        setIsLoading(false)
    }

    return <Grid container xs={12} xl={12} md={12} sm={12} className="mainDiv" >
        <Grid container xs={12} xl={12} md={12} sm={12} style={{ padding: '10px', height: '4vh' }} >
            <Grid item xs={6} xl={6} md={6} sm={6}>
                <span className='header1'>Song Details</span>
            </Grid>
            <Grid item xs={6} xl={6} md={6} sm={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Box>
                    <Button variant="contained" onClick={saveHandler}>Save</Button>
                </Box>
            </Grid>
        </Grid>
        <Grid item xs={12} xl={12} md={12} sm={12} style={{ height: '96vh' }}>
            <Grid item xs={10} xl={6} md={8} sm={10}  >
                {listData.map((item, index) => <Box style={{ width: '100%', display: 'flex', marginTop: '10px' }}>
                    <Box style={{ width: '70%' }} className={classes.root}>
                        <Textarea
                            placeholder="Type anything…"
                            value={item}
                            minRows={3}
                            onChange={(e) => { fieldChangeHandler(index, e.target.value) }} />
                    </Box>

                    <Box style={{ width: '50%', display: 'flex' }}>
                        <Box style={{ width: '50%', display: 'flex', justifyContent: 'center' }} >
                            {index === listData.length - 1 && <IconButton onClick={addHandler}>
                                <AddCircleIcon />
                            </IconButton>}
                        </Box>
                        <Box style={{ width: '50%', display: 'flex', justifyContent: 'center' }} >
                            {listData.length > 1 && <IconButton onClick={() => { removeHandler(index) }}>
                                <RemoveCircleIcon />
                            </IconButton>}
                        </Box>
                    </Box>
                </Box>)}


            </Grid>
        </Grid>
    </Grid >
}

export default DetailPage